.newsletter-email-input {
  border-radius: 0;
  height: 48px;
  display: flex;
  align-items: center;
  border: 1px solid @Gray20;
  border-right: 1px solid @Gray60;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: @Gray60;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: @Gray60;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: @Gray60;
  }
}

.signup-btn {
  white-space: nowrap;
  min-height: 0 !important;
  min-width: 48px !important;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
