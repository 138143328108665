@import '@{variablesLocation}';
@import '/Foundation/Styling/code/thule/global/media-queries.less';
@import '/Foundation/Common/code/Views/Foundation/Common/NewsletterInput.less';

.newsletter-component {
  background-color: @Black;
  position: relative;
  z-index: 1;

  .text-section,
  form {
    @media screen and (min-width: @screen-md) {
      max-width: 335px;
      margin: auto;
    }
  }

  .text-section {
    @media screen and (min-width: @screen-lg) {
      max-width: 800px;
    }

    .top-text {
      color: @Gray40;
      text-transform: uppercase;
      text-align: center;
    }

    .description {
      color: @White;
      text-align: center;
    }
  }

  form {
    @media screen and (min-width: @screen-lg) {
      max-width: 360px;
    }
  }

  hr {
    color: @Gray60;
  }

  input[type='checkbox'] {
    background-color: @Black;
    border-color: @Gray40;
    height: 16px;
    width: 16px;
    position: relative;
    border-radius: 2px;
  }

  .message {
    color: @White;
    margin: auto;

    @media screen and (min-width: @screen-lg) {
      max-width: 360px;
    }
  }

  .form-check-label {
    color: @Gray40;

    a {
      text-decoration: underline;
      color: @Gray40;

      &:hover {
        color: darken(@Gray40, 15%);
      }
    }
  }
}
